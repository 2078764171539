<template>
  <v-dialog v-model="data.dialog" persistent fullscreen hide-overlay>
    <v-card v-if="item">
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>تسجيل حضور - {{ item.stage.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            :loading="submitLoading"
            style="height: 100% !important;"
            dark
            color="error"
            class="full-btn"
            @click="cancel()"
          >
            ايقاف
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="text-center">
        <qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
        <h1>عدد المسجلين دخول {{ item.attend_count }}</h1>
        <h1>عدد المسجلين غياب {{ item.absent_count }}</h1>
        <h1>عدد المتبقين {{ item.student_count - item.attendances_count }}</h1>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'DayDialog',
  components: {
    QrcodeVue,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'show',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    item: null,
  }),
  computed: {
    value() {
      return `qr:${this.item.id}`;
    },
    size() {
      return Math.min(document.documentElement.clientWidth * 0.75, document.documentElement.clientHeight * 0.75);
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            await this.fetch(val.id);
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  methods: {
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
    async cancel() {
      await axios.post(`/days/edit/${this.item.id}`, {
        active: false,
      });
      this.close();
    },
    close() {
      this.$emit('update:table')
      this.data.dialog = false;
    },
    async fetch(id) {
      const day = await axios.get(`/days/${id}`);
      this.item = day.data.data;
      setTimeout(async () => {
        if (this.data.dialog === true) {
          await this.fetch(id);
        }
      }, 5000);
    },
  },
}
</script>

<style scoped>
.full-btn {
  height: 100% !important;
}
</style>
